import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ChartDataSets, ChartOptions, LinearTickOptions} from 'chart.js';
import {Color} from 'ng2-charts';
import {BaseChartDirective} from 'ng2-charts';
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import { Chart } from "chart.js";
import * as Draggable from 'chartjs-plugin-draggable';
import {GraphState} from '../graph-page/graph-page.component';
import {scale} from '../../../utils/math-utils';
import {ScaleConfig} from '../scale-selector/scale-selector.component';
import {GraphControlService} from '../../../services/graph-control.service';
import {FaultData} from '../../../models/graph-data';
import * as cloneDeep from 'clone-deep';
import {AnnotationService} from '../../../services/annotation/annotation.service';
import {ScaleService} from '../../../services/scale.service';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})
export class GraphComponent implements OnInit {

  graphConfig: GraphState;
  multiplier: number;

  @Input('graphConfig')
  set setSignalData(graphState: GraphState) {
    this.graphConfig = cloneDeep(graphState);
    this.graphConfig.signals = this.graphConfig.signals.map(signalConfig => {
      return {
        ...signalConfig,
        xMin: signalConfig.xMin * this.multiplier,
        xMax: signalConfig.xMax * this.multiplier,
        xDistance: signalConfig.xDistance * this.multiplier,
        signal: signalConfig.signal
      };
    });
    this.createGraphs();
  }

  @Input()
  faultData: FaultData;

  @Input('xMin')
  set setXMin(xMin: number) {
    this.xMin = xMin * this.multiplier;
    if (this.chart && this.chart.chart) {
      this.chart.chart.config.options.scales.xAxes[0].ticks.min = this.xMin;
      this.chart.chart.update();
    }
  }

  @Input('xMax')
  set setXMax(xMax: number) {
    this.xMax = xMax * this.multiplier;
    if (this.chart && this.chart.chart) {
      this.chart.chart.config.options.scales.xAxes[0].ticks.max = this.xMax;
      this.chart.chart.update();
    }
  }

  @Input('showPoints')
  set setShowPoints(show: boolean) {
    this.showPoints = show;
    if (this.chart && this.chart.chart) {
      this.chart.chart.config.options.elements.point.radius = show ? 2 : 0;
      this.chart.chart.config.options.elements.point.hitRadius = show ? 2 : 0;
      this.chart.chart.update();
    }
  }

  @Input('showGrid')
  set setShowGrid(show: boolean) {
    this.showGrid = show;
    if (this.chart && this.chart.chart) {
      this.chart.chart.config.options.scales.xAxes[0].gridLines.display = show;
      this.chart.chart.update();
    }
  }

  @Input('showTime')
  set setShowTime(show: boolean) {
    this.showTime = show;
    if (this.chart && this.chart.chart) {
      this.chart.chart.config.options.scales.xAxes[0].ticks.display = show;
      (this.chart.chart.config.options.scales.xAxes[0].ticks as LinearTickOptions).precision = 2;
      this.chart.chart.update();
    }
  }

  @Input('yScale')
  set setYScale(scaleConfig: ScaleConfig) {
    this.yMin = scaleConfig.yMin;
    this.yMax = scaleConfig.yMax;
    if (this.chart && this.chart.chart) {
      this.chart.chart.config.options.scales.yAxes[0].ticks.min = this.yMin;
      this.chart.chart.config.options.scales.yAxes[0].ticks.max = this.yMax;
      this.chart.chart.update();
    }
  }

  @Input() graphNumber: number;

  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;

  showPoints = false;
  showGrid = false;
  showTime = false;

  savedAnnotationValues = [];

  @ViewChild(BaseChartDirective, {static: false})
  chart: BaseChartDirective;

  private lineChartData: ChartDataSets[];

  private lineChartOptions: (ChartOptions & {annotation: any});

  private lineChartColors: Color[] = [];

  private lineChartLegend = true;
  private lineChartPlugins = [ChartAnnotation, Draggable];
  private lineChartType = 'line';

  private minXPixelDistanceBetweenLabels = 15;
  private minYPixelDistanceBetweenLabels = 15;

  constructor(private readonly graphControlService: GraphControlService,
              private readonly annotationService: AnnotationService,
              private readonly scaleService: ScaleService) {
    this.graphControlService.yMinMaxUpdated$.subscribe((event: {configs: Array<ScaleConfig>, fromCheckbox: boolean}) => {
      const myScale = event.configs.find(s => s.graphNumber === this.graphConfig.graphNumber);
      if (myScale) {
        if (myScale.autoScale) {
          this.yMin = Math.min(...this.graphConfig.signals.map(s => s.signal.signalMin));
          this.yMax = Math.max(...this.graphConfig.signals.map(s => s.signal.signalMax));
        } else {
          this.yMin = myScale.yMin;
          this.yMax = myScale.yMax;
        }
        if (myScale.graphNumber === 1) {
        }
        if (this.chart && this.chart.chart) {
          this.chart.chart.config.options.scales.yAxes[0].ticks.min = this.yMin;
          this.chart.chart.config.options.scales.yAxes[0].ticks.max = this.yMax;
          this.chart.chart.update();
        }
      }
    });

    this.scaleService.multiplier$.subscribe(multiplier => {
      this.multiplier = multiplier;
    })

    this.annotationService.scaledPositions$.subscribe(positions => {
      this.setAnnotationPositions(positions);
    });
  }

  ngOnInit() {
    BaseChartDirective.registerPlugin(ChartAnnotation);
    BaseChartDirective.registerPlugin(Draggable);
    const xTickOptions: LinearTickOptions = {
      display: this.showTime,
      precision: 2,
      beginAtZero: false,
      min: this.xMin,
      max: this.xMax
    };

    const yTickOptions: LinearTickOptions = {
      display: true,
      precision: 2,
      min: this.yMin,
      max: this.yMax
    };

    this.lineChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 0
      },
      legend: {
        display: true,
        align: 'start',
        labels: {
          fontSize: 14,
          fontStyle: 'bold'
        }
      },
      scales: {
        xAxes: [{
          type: 'linear',
          display: true,
          gridLines: {
            display: false
          },
          ticks: xTickOptions,
          afterTickToLabelConversion: (s) => this.removeXAxisScales(s)

        }],
        yAxes: [{
          type: 'linear',
          display: true,
          gridLines: {
            display: true
          },
          ticks: yTickOptions,
          afterTickToLabelConversion: (s) => this.removeYAxisScales(s)
        }]
      },
      annotation: {
        annotations: [
          {
            type: 'line',
            mode: 'vertical',
            scaleID: 'x-axis-0',
            value: this.savedAnnotationValues[0],
            borderColor: 'red',
            borderWidth: 4,
            borderDash: [4, 2],
            label: {
              enabled: false
            },
            draggable: true,
            onDrag: (event) => this.onAnnotationDrag(event)
          },
          {
            type: 'line',
            mode: 'vertical',
            scaleID: 'x-axis-0',
            value: this.savedAnnotationValues[1],
            borderColor: 'blue',
            borderWidth: 4,
            borderDash: [4, 2],
            label: {
              enabled: false
            },
            draggable: true,
            onDrag: (event) => this.onAnnotationDrag(event)
          }
        ]
      },
      elements: {
        point: {
          radius: 0,
          hitRadius: 0
        },
        line: {
          fill: false
        }
      }
    };
    this.createGraphs();
  }

  private removeXAxisScales(s: any) {
    if (s.ticks.length > 4) {
      const length = s.ticks.length;
      const width = s.width;
      const widthPerDistance = width / Math.abs(s.end - s.start);
      const pixelDistance1 = Math.abs((s.ticksAsNumbers[1] - s.ticksAsNumbers[0]) * widthPerDistance);
      const pixelDistance2 = Math.abs((s.ticksAsNumbers[length - 1] - s.ticksAsNumbers[length - 2]) * widthPerDistance);
      if (pixelDistance1 < this.minXPixelDistanceBetweenLabels) {
        s.ticks[1] = "";
      }
      if (pixelDistance2 < this.minXPixelDistanceBetweenLabels) {
        s.ticks[length - 2] = "";
      }
    }
  }

  private removeYAxisScales(s: any) {
    if (s.ticks.length > 4) {
      const length = s.ticks.length;
      const height = s.height;
      const heightPerDistance = height / Math.abs(s.end - s.start);
      const pixelDistance1 = Math.abs((s.ticksAsNumbers[1] - s.ticksAsNumbers[0]) * heightPerDistance);
      const pixelDistance2 = Math.abs((s.ticksAsNumbers[length - 1] - s.ticksAsNumbers[length - 2]) * heightPerDistance);
      if (pixelDistance1 < this.minYPixelDistanceBetweenLabels) {
        s.ticks[1] = "";
      }
      if (pixelDistance2 < this.minYPixelDistanceBetweenLabels) {
        s.ticks[length - 2] = "";
      }
    }
  }

  private onAnnotationDrag(event: any) {
    const chartLeft = event.subject.chart.chartArea.left;
    const chartWidth = event.subject.chart.width;
    let value = scale(event.x, chartLeft, chartWidth, this.xMin, this.xMax);
    if (value < this.xMin) {
      value = this.xMin;
    }
    if (value > this.xMax - this.graphConfig.signals[0].xDistance) {
      value = this.xMax - this.graphConfig.signals[0].xDistance;
    }
    const values = cloneDeep(this.savedAnnotationValues);
    values[event.subject.config.borderColor === 'red' ? 0 : 1] = value;
    this.annotationService.setScaledPositions(values);
  }

  private setAnnotationPositions(positions: Array<number>): void {
    this.savedAnnotationValues = positions;
    if (this.chart && this.chart.chart) {
      this.savedAnnotationValues.forEach((v, i) => {
        this.chart.chart.config.options['annotation'].annotations[i].value = v;
      });
      this.chart.chart.update();
    }
  }

  private createGraphs() {
    this.lineChartData = this.graphConfig.signals.map(signalConfig => {
      return {
        data: signalConfig.signal.signalData.map((y: number, i) => ({x: signalConfig.xMin + (i * signalConfig.xDistance), y: y})),
        label: signalConfig.signal.signalDisplayName + ' (' + signalConfig.signal.signalDisplayChar + ')',
        lineTension: 0,
        borderWidth: signalConfig.lineThickness
      };
    });
    this.lineChartColors = this.graphConfig.signals.map(signalConfig => ({
      borderColor: signalConfig.color
    }));
    if (this.chart && this.chart.chart) {
      this.chart.chart.update();
    }
  }

  private saveGraphImage() {
    const screenshot = this.chart.chart.canvas.toDataURL('png');
    let month = "";
    let day = "";
    let year = "";

    if (this.faultData.faultDate) {
      const date = new Date(this.faultData.faultDate);
      year = date.getFullYear().toString();
      month = ("0" + (date.getMonth() + 1)).slice(-2);
      day = ("0" + date.getDate()).slice(-2);

    }
    this.graphControlService.announceSaveGraphScreenshot(
      this.graphConfig.signals.map(s => s.signal.signalDisplayName || s.signal.signalCode).join('|') + " " + year + month + day,
      screenshot);
  }
}
